<template>
  <div class="accountWrap">
    <div class="facilityBox">
      <el-breadcrumb separator="/" style="margin:0 0 10px 0;">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>税务信息</el-breadcrumb-item>
        <el-breadcrumb-item>税务详情</el-breadcrumb-item>
      </el-breadcrumb>
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="发票任务单号：" prop="PrintTaskID">
          <el-input class="fromInp" v-model="manageForm.PrintTaskID" placeholder="输入发票号" @keyup.enter.native="search()">
          </el-input>
        </el-form-item>
        <!-- <el-form-item :label="activePage == 3 ? '货主单位：' : '服务商单位：'" prop="EnterpriseFullName" label-width="100px">
            <el-input placeholder="请输入内容" v-model="manageForm.EnterpriseFullName" @keyup.enter.native="search()">
            </el-input>
          </el-form-item> -->
        <!-- <el-form-item label="发票类型：" prop="InvoiceType" label-width="100px">
            <el-select v-model="manageForm.InvoiceType" clearable placeholder="请选择发票类型" @change="searchChange()">
              <el-option v-for="item in options" :key="item.Code" :label="item.Name" :value="item.Code"></el-option>
            </el-select>
          </el-form-item> -->
        <el-form-item label="开票类型：" prop="FeeType" label-width="100px">
          <el-select v-model="manageForm.FeeType" clearable placeholder="请选择开票类型" @keyup.enter.native="search()">
            <el-option label="全部" value=""></el-option>
            <el-option label="运费" value="0"></el-option>
            <el-option label="技术服务费" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发票号：" prop="InvoiceNumber" label-width="100px">
          <el-input class="fromInp" v-model="manageForm.InvoiceNumber" placeholder="输入发票号"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="开票日期" prop="date">
          <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="search1()">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search1()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm1()" icon="el-icon-delete">清空</el-button>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button size="medium" @click="routerBack()" icon="el-icon-arrow-left">返回</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="downloadInvoice('1')">下载电子发票
          </el-button>
        </el-form-item>
      </el-form>
      <!--数量金额总计-->
      <div class="settleBox">
        <div style="display:flex;">
          <div class="sum-item"><span class="sum-txt">发票数量(张)：</span><span class="sum-num">{{
            invoices.InvoiceCount ? invoices.InvoiceCount : '/'
          }}</span></div>

          <div class="sum-item"><span class="sum-txt">开票总金额：</span><span class="sum-num">{{
            invoices.Detail.Amount ? '￥' + invoices.Detail.Amount : '/'
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">运费：</span><span class="sum-num">{{
            invoices.Detail.Freight ? '￥' + invoices.Detail.Freight : '/'
          }}</span></div>
          <div class="sum-item"><span class="sum-txt">运费服务费/税费：</span><span class="sum-num">{{
            invoices.Detail.ExpensesOfTaxation ? '￥' + invoices.Detail.ExpensesOfTaxation : '/'
          }}</span></div>
        </div>
        <div style="display:flex; margin-top:10px;">
          <div class="sum-item">
            <span class="sum-txt">运单数量(条)：</span><span class="sum-num">{{
              invoices.WaybillCount ? invoices.WaybillCount : '/'
            }}</span>
          </div>

          <div class="sum-item"><span class="sum-txt">技术服务费不含税金额：</span><span class="sum-num">{{
            invoices.Detail.FreightTec ? '￥' +
          invoices.Detail.FreightTec : '/'
          }}</span>
          </div>
          <div class="sum-item"><span class="sum-txt">技术服务费/税费：</span><span class="sum-num">{{
            invoices.Detail.ExpensesOfTaxationTec
            ? '￥' +
            invoices.Detail.ExpensesOfTaxationTec : '/'
          }}</span>
          </div>
          <div class="sum-item"><span class="sum-txt">技术服务费含税金额：</span><span class="sum-num">{{
            invoices.Detail.AmountTec ? '￥' +
          invoices.Detail.AmountTec : '/'
          }}</span>
          </div>

        </div>
      </div>
      <el-table :data="tableData1" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="index" align="center" label="序号" width="50" fixed="left" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PrintTaskID" label="发票任务单号" width="200" fixed="left" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="tasksInfoDeatils(scope.row.PrintTaskID)">
              {{ scope.row.PrintTaskID }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ApplicantUserName" label="货主单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseName" label="服务商单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillCount" label="总计运单数量" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="FeeTypeName" label="开票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" width="100" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="CarriageAmount" label="运费/技术服务费" width="150" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.CarriageAmount ? '￥' + scope.row.CarriageAmount : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TaxAmount" label="服务费/税费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.TaxAmount ? '￥' + scope.row.TaxAmount : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayTotalAmount" label="开票总金额" width="110" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.PayTotalAmount ? '￥' + scope.row.PayTotalAmount : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceAmount" width="100" label="发票面额" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceNumber" label="发票号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceTime" label="开票日期" width="140" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CreateTime" label="开票申请时间" width="140" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <div style="margin: 10px 0 0 0">
        <!-- 分页 -->
        <el-pagination background class="pagination" @current-change="handleCurrentChange1"
          :current-page.sync="pagination1.page" :page-size="pagination1.pagesize"
          layout="total, prev, pager, next, jumper" :total="pagination1.total">
        </el-pagination>
      </div>
    </div>
    <el-dialog width="800px" :visible.sync="flag.showMap" append-to-body class="deep_dialog">
      <span slot="title">查看地图</span>
      <!-- 地图组件 -->
      <TXmap style="height: 600px" v-if="flag.showMap" ref="map" :list="mapList"></TXmap>
    </el-dialog>
    <!-- 车辆信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.carType" append-to-body class="deep_dialog">
      <span slot="title">车辆信息</span>
      <VehicleInfo :vehicleInfo="vehicleInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.carType = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 司机信息组件 -->
    <el-dialog width="1300px" :visible.sync="flag.showDriver" append-to-body class="deep_dialog">
      <span slot="title">司机信息</span>
      <DriverInfo :driverInfo="driverInfo" />
      <div class="btnWrap">
        <el-button type="primary" size="medium" @click="flag.showDriver = false">取消</el-button>
      </div>
    </el-dialog>
    <!-- 运单详情 -->
    <el-dialog width="80%" title="运单详情" :visible.sync="flag.showDetail" append-to-body class="deep_dialog">
      <el-form class="manageForm" :model="manageForm" :inline="true">
        <el-form-item label="车牌" prop="vehiclePlate">
          <el-input class="fromInp" v-model="manageForm.VehicleCarNumber" placeholder="请输入车牌"
            @keyup.enter.native="search()"></el-input>
        </el-form-item>
        <el-form-item label="开票日期" prop="date">
          <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd" range-separator="至"
            start-placeholder="开始日期" end-placeholder="结束日期" @change="searchChange()">
          </el-date-picker>
        </el-form-item>
        <el-form-item label-width="10px">
          <el-button type="primary" size="medium" @click="search()" icon="el-icon-search">搜索</el-button>
          <el-button type="primary" size="medium" @click="resetForm()" icon="el-icon-delete">清空</el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="downloadInvoice('2')">下载电子发票
          </el-button>
          <el-button type="primary" size="small" icon="el-icon-download" @click="bindFinal()">下载发票运单表格</el-button>
          <el-button type="primary" size="medium" @click="alluploadClick()" icon="el-icon-download">下载全部电子回单</el-button>
        </el-form-item>
      </el-form>
      <el-table :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" v-loading="loading">
        <el-table-column type="index" align="center" fixed="left" label="序号" width="50" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="WaybillID" fixed="left" label="运单号（自动生成，请勿填写）" width="220"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskType" label="业务类型" width="110" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="150" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsOwnerName" label="货主单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCodeHZ" label="货主税号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddressHZ" label="货主地址" width="260" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhoneHZ" label="货主电话" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNameHZ" label="货主开户行" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumberHZ" label="货主账号" width="240" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskDatetime" label="开票申请时间" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LoadingDatetime" label="运输起始时间" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="SignDatetime" label="运输结束时间" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsUnit" label="货品单位" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverAllotCount" label="货品数量" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverCarryPrice" label="运输单价" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.DriverCarryPrice ? '￥' + scope.row.DriverCarryPrice : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarriageTotalPrice" label="运费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">
              {{ scope.row.CarriageTotalPrice ? '￥' + scope.row.CarriageTotalPrice : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Rate" label="费率" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="ServiceCharge" label="服务费/税费" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.ServiceCharge ? '￥' + scope.row.ServiceCharge : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmount" label="含税金额" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.TotalAmount ? '￥' + scope.row.TotalAmount : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="DefaultRate" label="税率" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="NonTaxTotalAmount" label="不含税金额" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.NonTaxTotalAmount ? '￥' + scope.row.NonTaxTotalAmount : '/' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TaxFee" label="税额" width="100" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: #ff8b17">{{ scope.row.TaxFee ? '￥' + scope.row.TaxFee : '/' }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="CarNumber" width="100" label="车牌" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkInfo(scope.row)">
              {{ scope.row.CarNumber }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="VehicleType" label="车辆类型" width="100" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="GoodsName" label="货品名称" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginAddress" label="起始地" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.OriginAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="OriginProvince" label="起始地省份" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginCity" label="起始地城市" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="OriginArea" label="起始地区/县" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ArriveAddress" label="到达地" width="300" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="openMap(scope.row)">
              {{ scope.row.ArriveAddress }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ArriveProvince" label="到达地省份" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ArriveCity" label="到达地城市" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ArriveArea" label="到达地区/县" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color: blue; cursor: pointer" @click="checkUser(scope.row)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseFullName" label="服务商单位" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseCreditCode" label="服务商税号" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseAddress" label="服务商地址" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="EnterpriseContactsPhone" label="服务商电话" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerName" label="服务商开户行" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="BankerNumber" label="服务商账号" width="180" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="ElectronicReceiptURL" label="电子回单" fixed="right" width="250">
          <template slot-scope="scope">
            <el-button type="primary" size="small" @click="alluploadClick(scope.row)" icon="el-icon-download">
              下载回单
            </el-button>
            <el-button type="primary" size="small" @click="goFacility(scope.row)" icon="el-icon-download">
              下载合同
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div style="margin: 10px 0 0 0;display:flex;align-items:center">
        <el-pagination background class="pagination" @current-change="handleCurrentChange"
          :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
          :total="pagination.total">
        </el-pagination>
        <el-button type="primary" size="small" icon="el-icon-upload" @click="flag.uploadInvoiceDialog = true"
          style="margin-left:10px" v-if="activePage == 3">上传发票
        </el-button>
      </div>
      <div class="invoice" v-if="InvoiceImgUrl.length != 0">
        <div v-for="(item, index) in InvoiceImgUrl" :key="index">
          <el-image class="img" :src="item.InvoiceImgUrl" :preview-src-list="srcList" v-if="item.InvoiceImgUrl">
            <div slot="error" class="image-slot" style="display: flex; justify-content: center; margin-top: 30px;">
              <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
            </div>
          </el-image>
          <span v-else style="color: red;">已由系统回填信息</span>
          <el-tooltip :content="item.InvoiceImgUrl" v-if="item.InvoiceImgUrl">
            <p @click="downloadVoucher(item.InvoiceImgUrl)"
              style="width: 180px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color:blue">{{
                item.InvoiceImgUrl
              }}</p>
          </el-tooltip>
        </div>
      </div>
    </el-dialog>
    <!-- 上传发票dialog -->
    <el-dialog width="1500px" height="600px" :visible.sync="flag.uploadInvoiceDialog" :close-on-press-escape="false"
      :close-on-click-modal="false" v-if="flag.uploadInvoiceDialog">
      <el-tabs v-model="flag.activeTab">
        <el-tab-pane label="纸质发票" name="1">
          <div style="height: 60vh;overflow-y: scroll;">
            <!-- 提示 -->
            <el-alert style="margin-bottom: 8px;" type="warning" :closable="false">
              <span slot="title">
                <span>温馨提示：1.上传发票，将自动OCR识别发票信息；2.上传异常、需手动填写发票3项信息，平台将人工审核，并在全国增值税发票查验平台校验真伪</span><br />
              </span>
            </el-alert>
            <input type="file" style="display:none" @change="handleInvoiceUpload" multiple ref="invoice">
            <input type="file" style="display:none" @change="handleSpecialInvoiceUpload" multiple ref="specialInvoice">
            <input type="file" style="display:none" @change="handleVoucherUpload" ref="voucher">

            <!-- 常见发票 -->
            <!-- <strong style="font-size: 15px; font-weight: 600;">常见发票</strong>
            <el-button type="primary" style="margin: 16px 16px;" @click="$refs.invoice.click()"
              :loading="flag.uploadNormalInvoice" icon="el-icon-upload2">批量上传</el-button>
            <span>注明：最多一次可上传10张</span>
            <div class="uploadImgWrap">
              <div class="item" v-for="(item, index) in invoiceImgList" :key="index">
                <i class="delete el-icon-delete" @click="handleInvoiceDelete(index)"></i>
                <el-image class="img" :src="item.picUrl" :preview-src-list="[item.picUrl]">
                  <div slot="error" class="image-slot" style="display: flex; justify-content: center; margin-top: 30px;">
                    <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
                  </div>
                </el-image>
                <el-tooltip :content="item.picUrl">
                  <p @click="downloadVoucher(item.picUrl)"
                    style="width: 180px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color:blue">
                    {{
                      item.picUrl
                    }}</p>
                </el-tooltip>
              </div>
            </div> -->
            <!-- 分页 -->
            <!-- <el-pagination background @current-change="handleCurrentChange3" :current-page.sync="pagination3.page"
              :page-size="pagination3.pagesize" layout="total, prev, pager, next, jumper" :total="pagination3.total">
            </el-pagination> -->

            <hr style="margin-top: 16px;">

            <!-- 特殊发票 -->
            <span style="font-size: 15px; font-weight: 600;">上传发票</span>
            <el-button type="primary" style="margin: 16px 16px;" @click="$refs.specialInvoice.click()"
              :loading="flag.uploadSpecialInvoice" icon="el-icon-upload2">批量上传</el-button>
            <span>注明：最多一次可上传10张</span>
            <!-- 表格 -->
            <vxe-table border resizable show-overflow :data="specialInvoiceList"
              :edit-config="{ trigger: 'click', mode: 'cell' }" class="special-table" v-if="flag.activeTab=='1'">
              <vxe-table-column align="center" field="index" title="序号" width="14%"></vxe-table-column>
              <vxe-table-column align="center" field="picUrl" title="发票图片" width="14%">
                <template #default="{ row }">
                  <el-image style="width:40px;height:40px;margin-top:5px;" :src="row.picUrl"
                    :preview-src-list="[row.picUrl]" v-if="row.picUrl"></el-image>
                    <span v-else style="color: red;">已由系统回填信息</span>
                </template>
              </vxe-table-column>
              <vxe-table-column align="center" field="invoiceCode" title="发票代码（必填）" width="14%"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="invoiceNum" title="发票号码（必填）" width="14%"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="CreateTime" title="开票日期（必填）" width="14%"
                :edit-render="{ autoselect: true }">
                <template #edit="{ row }">
                  <el-input v-model="row.CreateTime" type="date" value-format="yyyy-MM-dd" />
                </template>
              </vxe-table-column>
              <vxe-table-column align="center" field="IsOcr" title="是否识别" width="14%">
                <template #default="{ row }">
                  <el-tag :type="row.IsOcr ? 'success' : 'danger'">{{ row.IsOcr ? '是' : '否' }}</el-tag>
                </template>
              </vxe-table-column>
              <!-- <vxe-table-column align="center" field="money" title="开具金额（必填）" width="150"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="total" title="价税合计" width="150"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column>
              <vxe-table-column align="center" field="verifyCode" title="校验码" width="150"
                :edit-render="{ name: 'input', autoselect: true }"></vxe-table-column> -->
              <vxe-table-column align="center" title="操作" width="15%">
                <template #default="{ row }">
                  <el-button type="danger" @click="deleteSpecialInvoiceDelete(row.index)">删除</el-button>
                </template>
              </vxe-table-column>
            </vxe-table>
            <!-- 分页 -->
            <el-pagination background @current-change="handleCurrentChange4" style="margin-top: 8px;"
              :current-page.sync="pagination4.page" :page-size="pagination4.pagesize"
              layout="total, prev, pager, next, jumper" :total="pagination4.total">
            </el-pagination>
          </div>

        </el-tab-pane>

        <el-tab-pane label="清单" name="2">
          <div style="height: 60vh;overflow-y: scroll;">

            <!-- 提示 -->
            <el-alert style="margin-bottom: 8px;" type="warning" :closable="false">
              <span slot="title">
                <span>温馨提示：上传与发票相关的清单、票据等。</span><br />
              </span>
            </el-alert>
            <el-button type="primary" :loading="flag.uploadingInvoice" @click="$refs.voucher.click()"
              icon="el-icon-upload2">上传清单</el-button>

            <!-- 凭证列表 -->
            <div class="uploadImgWrap">
              <div class="item" v-for="(item, index) in voucherList" :key="index">
                <i class="delete el-icon-delete" @click="handleVoucherDelete(pagination5.page, index)"></i>
                <el-image class="img" :src="item.picUrl" :preview-src-list="[item.picUrl]">
                  <div slot="error" class="image-slot" style="display: flex; justify-content: center; margin-top: 30px;">
                    <i class="el-icon-picture-outline" style="font-size: 50px;"></i>
                  </div>
                </el-image>
                <el-tooltip :content="item.picUrl">
                  <p @click="downloadVoucher(item.picUrl)"
                    style="width: 180px;white-space: nowrap;overflow: hidden; text-overflow: ellipsis;color:blue">
                    {{
                      item.picUrl
                    }}</p>
                </el-tooltip>
              </div>
            </div>
            <!-- 分页 -->
            <el-pagination background @current-change="handleCurrentChange5" style="margin-top: 8px;"
              :current-page.sync="pagination5.page" :page-size="pagination5.pagesize"
              layout="total, prev, pager, next, jumper" :total="pagination5.total">
            </el-pagination>
          </div>
        </el-tab-pane>
      </el-tabs>
      <!-- 按钮 -->
      <div style="text-align:center; margin-top: 8px;">
        <el-button type="primary" size="mini" :loading="flag.uploadingInvoice" @click="handleCloseUploadInvoiceDialog">
          确认</el-button>
      </div>
    </el-dialog>
    <!-- 上传运单错误信息dialog -->
    <el-dialog :visible.sync="flag.uploadErrorDialog" title="错误说明" center append-to-body>
      <div style="height:60vh;overflow-y:scroll">
        <el-table :data="uploadErrorList" :header-cell-class-name="cellClass"
          :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
          <el-table-column align="center" prop="Hint" label="错误信息" show-overflow-tooltip></el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import TXmap from "@/components/commonCmpt/TXmap";
import {
  TaskDetailList,
  PrintInvoiceInfoList,
  ivoiceTasks
} from "@/api/taxrelated/taxinfo/index";
import { upExport, downloadContract, downloadBankUrl, taskSummary } from "@/api/finance/account/index";
import { printDataStore, specialInvoice, uploadInvoices, addInvoiceChecklist } from '@/api/supplierWaybill/basicWaybill/makeInvoice'
import { _downloadFile } from "@/utils/utils";
import { _isNumberOrLetter, _integer, _polishing, _isAllNumber } from '@/utils/verify'
import { invoiceUrl, DisInvoices, RefundWaybill } from "@/api/waybill/waybillManage/index";
import VehicleInfo from "@/components/businessCmpt/vehicleInfo";
import DriverInfo from "@/components/businessCmpt/driverInfo";
import {
  getCarInfo,
  getDriverDetails,
  getMapPath,
  getDataDict
} from "@/api/common/common";
export default {
  data() {
    return {
      uploadErrorList: [],
      pagination3: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination4: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      pagination5: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      currentItem: {
        invoiceImgList: [], //正常发票
        voucherList: [], //清单
        specialInvoiceList: [], // 特殊发票
      },
      // 发票类型数据
      options: [
        {
          Code: '',
          Name: '全部'
        }
      ],
      activePage: 1,
      // 筛选条件数据
      manageForm: {
        pageSize: 10,
        pageIndex: 1,
        VehicleCarNumber: "",
      },
      pagination1: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      tableData1: [],
      //分页控件相关参数
      pagination: {
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      // 表单数据
      tableData: [],
      // 发票数据
      InvoiceImgUrl: [],
      // 已选中的时间
      date: ["", ""],
      // 发票号
      InvoiceNumber: "",
      // 控制弹窗
      flag: {
        // 轨迹回放弹窗
        showMap: false,
        // 车辆信息弹窗
        carType: false,
        // 司机信息弹窗
        showDriver: false,
        showDetail: false, // 运单详情弹窗
        activeTab: "1",
        uploadInvoiceDialog: false, // 上传发票弹窗
        uploadingInvoice: false, //上传发票中
        uploadNormalInvoice: false, //上传正常发票中
        uploadSpecialInvoice: false, //上传特殊发票中
        uploadErrorDialog: false
      },
      // 车辆信息
      vehicleInfo: {},
      // 司机信息
      driverInfo: {},
      // 图片放大预览
      srcList: [],
      // 轨迹列表
      mapList: [],
      // 表格loading
      loading: true,
      queryParams: {
        InvoiceTaskID: '',//结算单号
        InvoiceNumber: "", //发票号
        InvoiceType: '', //发票类型
        FeeType: '',
        pageIndex: 0,
        StartDatetime: '', //开始时间 
        EndDatetime: '', //结束时间
        currentEnterprise: '1', //企业类型
        EnterpriseFullName: '', //企业名称
      },
      invoiceId: null, // 发票单号
      taskId: null, // 结算单号
      invoices: {
        Detail: {}
      },
    };
  },
  created() {
    this.queryParams.pageIndex = this.$route.query.pageIndex ? this.$route.query.pageIndex : 1
    this.queryParams.StartDatetime = this.$route.query.StartDatetime ? this.$route.query.StartDatetime : ''
    this.queryParams.EndDatetime = this.$route.query.EndDatetime ? this.$route.query.EndDatetime : ''
    this.queryParams.currentEnterprise = this.$route.query.currentEnterprise ? this.$route.query.currentEnterprise : ''
    this.queryParams.EnterpriseFullName = this.$route.query.EnterpriseFullName ? this.$route.query.EnterpriseFullName : ''
    this.queryParams.InvoiceNumber = this.$route.query.InvoiceNumber ? this.$route.query.InvoiceNumber : ''
    this.queryParams.InvoiceType = this.$route.query.InvoiceType ? this.$route.query.InvoiceType : ''
    this.queryParams.FeeType = this.$route.query.FeeType ? this.$route.query.FeeType : ''
    this.queryParams.InvoiceTaskID = this.$route.query.InvoiceTaskID || ''
    this.taskId = this.$route.query.taskId
    this.activePage = this.$route.query.activePage
    // // 获取数据列表
    this.tasksTableData()
    // 获取发票类型
    getDataDict({ type: 20 }).then((res) => {
      this.options = this.options.concat(res.patterSetInfo)
    });
  },
  methods: {
    //关闭上传弹窗
    handleCloseUploadInvoiceDialog() {
      let flag = false //是否还有必填项每天
      this.currentItem.specialInvoiceList.forEach(item => {
        if (!item.invoiceCode || !item.invoiceNum || !item.CreateTime) {
          flag = true
        }
      })
      if (flag) {
        this.$message.warning('发票代码、发票号码、开票日期都为必填项')
      } else {
        try {
          this.currentItem.specialInvoiceList.forEach(item => {
            this.currentItem.specialInvoiceList.forEach(el => {
              if (item.invoiceCode === el.invoiceCode && item.invoiceNum === el.invoiceNum && item.index != el.index) {
                this.$message.error('发票中有重复发票')
                throw new Error()
              }
            })
            if (!_isNumberOrLetter(item.invoiceCode)) {
              this.$message.error(`发票-序号${item.index}的发票代码只能为数字和字母`)
              throw new Error()
            }
            if (item.invoiceCode.length >32) {
              this.$message.error(`发票-序号${item.index}的发票代码需小于32个字符`)
              throw new Error()
            }
            if (!_isNumberOrLetter(item.invoiceNum)) {
              this.$message.error(`发票-序号${item.index}的发票号码只能为数字和字母`)
              throw new Error()
            }
            if (item.invoiceNum.length > 32) {
              this.$message.error(`发票-序号${item.index}的发票号码需小于32个字符`)
              throw new Error()
            }
            // if (!_integer(item.money)) {
            //   this.$message.error(`特殊发票-序号${item.index}的开具金额只能为数字，且仅保留两位小数`)
            //   throw new Error()
            // }
            // if (Number(item.money) > 10000000) {
            //   this.$message.error(`特殊发票-序号${item.index}的开具金额不能大于一千万`)
            //   throw new Error()
            // }
            // if (item.total) {
            //   if (!_integer(item.total)) {
            //     this.$message.error(`特殊发票-序号${item.index}的价税合计只能为数字，且仅保留两位小数`)
            //     throw new Error()
            //   }
            //   if (Number(item.total) > 20000000) {
            //     this.$message.error(`特殊发票-序号${item.index}的价税合计不能大于二千万`)
            //     throw new Error()
            //   }
            // }
            // if (item.verifyCode) {
            //   if (!_isAllNumber(item.verifyCode)) {
            //     this.$message.error(`特殊发票-序号${item.index}的校验码只能为纯数字`)
            //     throw new Error()
            //   }
            // }
          })
          //处理金额为后面有两位小数点的格式
          // this.currentItem.specialInvoiceList.forEach(item => {
          //   item.money = item.money ? _polishing(item.money) : null
          //   item.total = item.total ? _polishing(item.total) : null
          // })
          this.printDataStore()
        } catch (error) {
          console.log(error)
        }
      }
    },
    //上传正常发票
    handleInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files
      if (fileList.length > 10) {
        this.$message.error('单次上传不可超过十张发票')
        this.$refs.invoice.value = null
        return
      }
      this.flag.uploadingInvoice = true
      this.flag.uploadNormalInvoice = true
      let count = 0 //已经上传完毕了多少条
      let fileListLength = fileList.length
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData()
        formData.append('file', fileList[i])
        uploadInvoices(formData).then(res => {
          if (!res.photoInfo[0].OcrInfo.data.发票号码 || !res.photoInfo[0].OcrInfo.data.发票代码) {
            this.$message.warning('该发票识别不出发票号码等信息，请上传到特殊发票中再手动输入信息')
            return
          }
          this.currentItem.invoiceImgList.push({
            index: this.currentItem.invoiceImgList.length + 1,
            picUrl: res.photoInfo[0].PhotoUrl,
            invoiceNum: res.photoInfo[0].OcrInfo.data.发票号码,
            invoiceContent: res.photoInfo[0].OcrInfo.data,
            money: res.photoInfo[0].OcrInfo.data.不含税金额, //开具金额
            total: res.photoInfo[0].OcrInfo.data.发票金额, //价税合计
            verifyCode: res.photoInfo[0].OcrInfo.data.校验码, //校验码
            invoiceCode: res.photoInfo[0].OcrInfo.data.发票代码,
            CreateTime: res.photoInfo[0].OcrInfo.data.开票日期.replace(/年/g, '-').replace(/月/g, '-').replace(/日/g, '')
          })
          this.pagination3.total = this.pagination3.total + 1
        }).finally(() => {
          this.$refs.invoice.value = null
          count = count + 1
          if (count === fileListLength) {
            this.flag.uploadingInvoice = false
            this.flag.uploadNormalInvoice = false
          }
        })
      }
    },
    //删除正常发票
    handleInvoiceDelete(index) {
      this.$confirm('确认删除该发票吗', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.pagination3.total = this.pagination3.total - 1
        this.currentItem.invoiceImgList.splice(index, 1)
      }).catch()
    },
    //上传清单
    handleVoucherUpload(e) {
      this.flag.uploadingInvoice = true
      let formData = new FormData()
      formData.append('file', e.srcElement.files[0] || e.target.files[0])
      addInvoiceChecklist(formData).then(res => {
        this.currentItem.voucherList.push({
          index: this.currentItem.voucherList.length + 1,
          picUrl: res.checkList.ImageUrl,
          name: res.checkList.Name
        })
        this.pagination5.total = this.pagination5.total + 1
      }).finally(() => {
        this.$refs.voucher.value = null
        this.flag.uploadingInvoice = false
      })
    },
    //删除一个凭证
    handleVoucherDelete(page, index) {
      this.$confirm('确认删除该凭证吗', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.pagination5.total = this.pagination5.total - 1
        this.currentItem.voucherList.splice((page - 1) * this.pagination5.pagesize + index, 1)
      }).catch()
    },
    //上传特殊发票信息
    handleSpecialInvoiceUpload(e) {
      let fileList = e.srcElement.files || e.target.files
      if (fileList.length > 10) {
        this.$message.error('单次上传不可超过十张发票')
        this.$refs.specialInvoice.value = null
        return
      }
      let count = 0 //已经上传完毕了多少条
      let fileListLength = fileList.length
      this.flag.uploadingInvoice = true
      this.flag.uploadSpecialInvoice = true
      for (let i = 0; i < fileListLength; i++) {
        let formData = new FormData()
        formData.append('file', fileList[i])
        DisInvoices(formData).then(res => {
          this.currentItem.specialInvoiceList.push({
            index: this.currentItem.specialInvoiceList.length + 1,
            picUrl: res.data.InvoiceImgUrl,
            invoiceNum: res.data.InvoiceNum,
            invoiceCode: res.data.InvoiceCode, //发票代码
            money: res.data.AmountIssued, //开具金额
            total: res.data.TaxIncreaseAlloy, //价税合计
            verifyCode: res.data.CheckCode, //校验码
            CreateTime: res.data.InvoiceTime,
            IsOcr: res.data.IsOcr // 是否Ocr识别
          })
          this.pagination4.total = this.pagination4.total + 1
        }).finally(() => {
          this.$refs.specialInvoice.value = null
          count = count + 1
          if (count === fileListLength) {
            this.flag.uploadingInvoice = false
            this.flag.uploadSpecialInvoice = false
          }
        })
      }
    },
    //删除特殊发票
    deleteSpecialInvoiceDelete(index) {
      this.$confirm('确认删除该发票吗', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.currentItem.specialInvoiceList.forEach((item, inx) => {
          if (item.index == index) {
            this.pagination4.total = this.pagination4.total - 1
            this.currentItem.specialInvoiceList.splice(inx, 1)
          }
        })
        this.currentItem.specialInvoiceList.forEach((item, index) => {
          item.index = index + 1
        })
      }).catch()
    },
    //暂存
    printDataStore() {
      const loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      })
      let invoices = [] //正常发票
      let specials = [] //特殊发票
      let checks = [] //凭证
      // this.currentItem.invoiceImgList.forEach(el => {
      //   invoices.push({
      //     InvoiceImgUrl: el.picUrl, //发票图片地址
      //     InvoiceNum: el.invoiceNum, //发票号码
      //     PrintInvoiceTaskID: this.invoiceId, //打票单号
      //     InvoiceTime: el.CreateTime,
      //     InvoiceCode: el.invoiceCode,
      //     AmountIssued: el.money, //开具金额
      //     TaxIncreaseAlloy: el.total, //加税合计
      //     CheckCode: el.verifyCode, //校验码
      //     InvcieContent: JSON.stringify(el.invoiceContent), //发票内容
      //   })
      // })
      this.currentItem.specialInvoiceList.forEach(el => {
        if (el.IsOcr) {
          invoices.push({
            InvoiceImgUrl: el.picUrl, //发票图片地址
            InvoiceNum: el.invoiceNum, //发票号码
            PrintInvoiceTaskID: this.invoiceId, //打票单号
            InvoiceCode: el.invoiceCode,
            InvoiceTime: el.CreateTime,
            AmountIssued: el.money, //开具金额
            TaxIncreaseAlloy: el.total, //加税合计
            CheckCode: el.verifyCode, //校验码
            InvcieContent: JSON.stringify(el.invoiceContent), //发票内容
          })
        } else {
          specials.push({
            InvoiceImgUrl: el.picUrl, //发票图片地址
            InvoiceNum: el.invoiceNum, //发票号码
            PrintInvoiceTaskID: this.invoiceId, //打票单号
            InvoiceCode: el.invoiceCode,
            InvoiceTime: el.CreateTime,
            AmountIssued: el.money || 0, //开具金额
            TaxIncreaseAlloy: el.total || 0, //加税合计
            CheckCode: el.verifyCode, //校验码
            InvcieContent: JSON.stringify(el.invoiceContent), //发票内容
          })
        }
      })
      this.currentItem.voucherList.forEach(el => {
        checks.push({
          ImageUrl: el.picUrl,
          PrintTaskId: this.invoiceId, //打票单号
          Name: el.name
        })
      })
      printDataStore({ Json: JSON.stringify({ bOnlyInsertData: true, InvoiceTaskId: this.taskId, expressName: '', trackingNum: '', Status: '', Invoices: invoices, Specials: specials, Checks: checks }) }).then(res => {
        this.flag.uploadInvoiceDialog = false
        this.InfoList(this.invoiceId)
        this.currentItem = {
          invoiceImgList: [], //正常发票
          voucherList: [], //清单
          specialInvoiceList: [], // 特殊发票
        }
      }).catch(res => {
        this.uploadErrorList = res.data.invoices
        this.flag.uploadErrorDialog = true
      }).finally(() => {
        loading.close()
      })
    },
    cellClass(row) {
      if (row.columnIndex === 0) {
        return 'DisableSelection'
      }
    },
    //分页回调
    handleCurrentChange3(e) {
      this.pagination3.page = e
    },
    //分页回调
    handleCurrentChange4(e) {
      this.pagination4.page = e
    },
    //分页回调
    handleCurrentChange5(e) {
      this.pagination5.page = e
    },
    // 汇总开票相关信息
    taskSummary() {
      let data = {
        InvoiceNumber: this.manageForm.InvoiceNumber,
        InvoiceTaskID: this.taskId,
        StartDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[0] : '',
        EndDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[1] : '',
        InvoiceType: this.manageForm.InvoiceType,
        FeeType: this.manageForm.FeeType,
        DemanderName: this.activePage == 3 ? this.manageForm.EnterpriseFullName : '',
        SupplierName: this.activePage == 2 ? this.manageForm.EnterpriseFullName : '',
        RoleType: this.activePage,
      }
      taskSummary({ Json: JSON.stringify(data) }).then((res) => {
        this.invoices = res.data || { Detail: {} };
      });
    },
    searchChange() {
      this.search()
    },
    // 返回上一页
    routerBack() {
      this.$router.push({
        path: "/taxrelated/taxinfo/index",
        query: {
          pageIndex: this.queryParams.pageIndex,
          StartDatetime: this.queryParams.StartDatetime ? this.queryParams.StartDatetime : '',
          EndDatetime: this.queryParams.EndDatetime ? this.queryParams.EndDatetime : '',
          currentEnterprise: this.queryParams.currentEnterprise ? this.queryParams.currentEnterprise : '1',
          EnterpriseFullName: this.queryParams.EnterpriseFullName ? this.queryParams.EnterpriseFullName : '',
          InvoiceNumber: this.queryParams.InvoiceNumber ? this.queryParams.InvoiceNumber : '',
          InvoiceType: this.queryParams.InvoiceType ? this.queryParams.InvoiceType : '',
          FeeType: this.queryParams.FeeType ? this.queryParams.FeeType : '',
          InvoiceTaskID: this.queryParams.InvoiceTaskID || '',
          DriverName: this.$route.query.DriverName || '',
          activePage: this.activePage
        },
      });
    },
    downloadVoucher(url) {
      window.open(url, '_blank')
    },
    // 下载合同
    goFacility(item) {
      this.loading = true
      let data = {
        WaybillID: item.WaybillID
      }
      downloadContract({ Json: JSON.stringify(data) }).then(res => {
        window.location.href = res.pathName
      }).finally(() => {
        this.loading = false
      })
    },
    // 查看司机信息
    checkUser(item) {
      this.loading = true
      let data = {}
      getDriverDetails({ UserID: item.DriverUserID }).then((res) => {
        data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo)
        this.driverInfo = data
        this.flag.showDriver = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 查看车辆信息
    checkInfo(item) {
      this.loading = true
      let params = {
        CarNumber: item.CarNumber
      }
      let data = {}
      getCarInfo(params).then(res => {
        data = Object.assign({}, res.data.vehicle_baseInfo, res.data.vehicle_confirmInfo)
        this.vehicleInfo = data
        this.flag.carType = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 下载电子发票
    downloadInvoice(type) {
      this.loading = true;
      let params = {
        InvoiceTask: type == 1 ? this.taskId : this.invoiceId,
      };
      invoiceUrl(params).then((res) => {
        window.location.href = res.receipt.pathName;
      }).finally(() => {
        this.loading = false;
      });
    },
    //下载发票运单表格
    bindFinal() {
      this.loading = true;
      let params = {
        invoiceTaskId: this.taskId
      }
      RefundWaybill(params).then(res => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      });
    },
    // 下载全部电子回单
    alluploadClick(item) {
      this.loading = true
      let json = {}
      if (item) {
        json.InvoiceTaskIDs = [this.invoiceId],
          json.WaybillIDs = [item.WaybillID]
      } else {
        json.InvoiceTaskIDs = [this.invoiceId],
          json.WaybillIDs = []
        this.tableData.forEach((item) => {
          json.WaybillIDs.push(item.WaybillID)
        })
      }
      downloadBankUrl(json).then(res => {
        window.location.href = res.data
      }).finally(() => {
        this.loading = false;
      })
    },
    //打开地图
    openMap(item) {
      this.loading = true
      let params = {
        searchWord: item.CarNumber,
        stDate: item.LoadingDatetime,
        edDate: item.SignDatetime,
        coordType: 2,
        wayBillId: item.WaybillID
      };
      getMapPath({ json: JSON.stringify(params) }).then((res) => {
        this.mapList = res.Track;
        this.flag.showMap = true;
      }).finally(() => {
        this.loading = false
      });
    },
    // 清空搜索
    resetForm() {
      this.loading = true;
      this.date = ["", ""];
      this.manageForm.VehicleCarNumber = "";
      this.pagination.pageIndex = 1;
      this.tasksInfoDeatils(this.invoiceId);
    },
    // 清空搜索
    resetForm1() {
      this.loading = true;
      this.pagination1.page = 1;
      this.date = ["", ""];
      this.manageForm = {
        InvoiceNumber: "",
        PrintTaskID: '',
        TaskDatetime: ['', ''],
        InvoiceType: "",
        FeeType: '',
        EnterpriseFullName: "",
        currentEnterprise: '1',
      };
      this.tasksTableData();
    },
    //分页控件页码change事件回调
    handleCurrentChange(e) {
      this.loading = true;
      this.pagination.pageIndex = e;
      this.tasksInfoDeatils(this.invoiceId);
    },
    handleCurrentChange1(e) {
      this.loading = true;
      this.pagination1.pageIndex = e;
      this.tasksTableData();
    },
    // 搜索
    search() {
      this.loading = true;
      this.pagination.pageIndex = 1;
      this.tasksInfoDeatils(this.invoiceId);
    },
    // 搜索发票单号
    search1() {
      this.loading = true;
      this.pagination1.pageIndex = 1;
      this.tasksTableData();
    },
    // 获取数据列表
    tasksInfoDeatils(id) {
      this.invoiceId = id
      let data = {
        pageSize: this.pagination.pagesize,
        pageIndex: this.pagination.page,
        StartDatetime: this.date[0],
        EndDatetime: this.date[1],
        RoleType: this.activePage,
        PrintTaskID: id,
        VehicleCarNumber: this.manageForm.VehicleCarNumber
      }
      TaskDetailList({ Json: JSON.stringify(data) }).then((res) => {
        this.tableData = res.invoices;
        this.pagination.total = Number(res.totalRowCount);
        this.flag.showDetail = true
        // 获取发票数据
        this.InfoList(id);
      }).finally(() => {
        this.loading = false
      })
    },
    // 获取发票任务单数据列表
    tasksTableData() {
      let params = {
        pageSize: this.pagination1.pagesize,
        pageIndex: this.pagination1.page,
        InvoiceNumber: this.manageForm.InvoiceNumber || '',
        PrintTaskID: this.manageForm.PrintTaskID,
        InvoiceTaskID: this.taskId,
        StartDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[0] : '',
        EndDatetime: this.manageForm.TaskDatetime ? this.manageForm.TaskDatetime[1] : '',
        InvoiceType: this.manageForm.InvoiceType || '',
        FeeType: this.manageForm.FeeType || '',
        DemanderName: this.activePage == 3 ? this.manageForm.EnterpriseFullName : '',
        SupplierName: this.activePage == 2 ? this.manageForm.EnterpriseFullName : '',
        RoleType: this.activePage,
      };

      ivoiceTasks({ Json: JSON.stringify(params) }).then((res) => {
        this.tableData1 = res.invoices;
        this.pagination1.total = Number(res.totalRowCount);
        this.taskSummary()
      }).finally(() => {
        this.loading = false;
      })
    },
    // 查询发票图片
    InfoList(id) {
      this.srcList = []
      this.InvoiceImgUrl = []
      PrintInvoiceInfoList({ PrintTaskID: id }).then(
        (res) => {
          if (res.invoices && res.invoices.length) {
            this.InvoiceImgUrl = res.invoices;
            res.invoices.forEach((item) => {
              this.srcList.push(item.InvoiceImgUrl);
            });
          }
        }
      );
    },
  },
  computed: {
    //正常发票分页
    invoiceImgList() {
      try {
        return this.currentItem.invoiceImgList.slice((this.pagination3.pagesize * (this.pagination3.page - 1)), (this.pagination3.pagesize * this.pagination3.page))
      } catch (error) {

      }
    },
    //特殊发票分页
    specialInvoiceList() {
      try {
        return this.currentItem.specialInvoiceList.slice((this.pagination4.pagesize * (this.pagination4.page - 1)), (this.pagination4.pagesize * this.pagination4.page))
      } catch (error) {

      }
    },
    //发票凭证分页
    voucherList() {
      try {
        return this.currentItem.voucherList.slice((this.pagination5.pagesize * (this.pagination5.page - 1)), (this.pagination5.pagesize * this.pagination5.page))
      } catch (error) {

      }
    }
  },
  components: {
    TXmap,
    VehicleInfo,
    DriverInfo,
  },
};
</script>

<style lang="scss" scoped>
.facilityBox {
  .topbtns {
    padding-bottom: 10px;
    margin: 0px 0px 10px;
    border-bottom: 1px solid #eee;
  }

  // margin-top: 30px;
}

.settleBox {
  margin-bottom: 10px;
  padding: 10px 16px;
  background-color: #f0f0f0;
  color: #666;
}

.sum-item {
  color: rgb(255, 139, 23);
  margin-right: 15px;
  white-space: nowrap;

  .sum-txt {
    color: #333;
    font-weight: bold;
    text-align: right;
    width: 160px;
    display: inline-block;
  }

  .sum-num {
    display: inline-block;
    width: 200px;
  }
}

.invoice {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;

  div {
    margin-top: 10px;
    width: 200px;
    height: 124px;
    border-radius: 10px;
    margin-right: 10px;

    .mg {
      width: 100%;
      height: 100%;
      border-radius: 10px;
    }
  }
}

.uploadImgWrap {
  display: flex;
  flex-wrap: wrap;
  width: 1100px;
  min-height: 50px;

  .item {
    width: 18%;
    margin: 16px 10px;
    position: relative;
    display: flex;
    flex-direction: column;

    .delete {
      cursor: pointer;
      position: absolute;
      font-size: 30px;
      position: absolute;
      bottom: 30px;
      right: 30px;
      z-index: 9999;
    }

    span {
      width: 200px;
      text-align: center;
    }
  }
}

.head {
  display: flex;

  .btn {
    vertical-align: top;
    margin: 15px 0 0 16px;
  }
}

.btnWrap {
  width: 100%;
  text-align: center;
  margin-top: 32px;
}

.deep_dialog {
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
  }

  ::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
    text-align: center;
  }

  ::v-deep .el-dialog__body {
    padding: 20px;
  }
}
</style>